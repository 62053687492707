import axios from "axios";
const instance = axios.create({
  baseURL: "https://www.dorartmgmt.net/admin/Values/",
 // baseURL: "http://localhost:52000/Values/",
  headers: {
    "Content-Type": "application/json",
  },
});
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
export default instance;
