import ApiService from "../service/ApiService";

export const ApiValues = {
  namespaced: true,
  actions: {
    getPackages() {
        return ApiService.getPackages().then(
          response => {
            return Promise.resolve(response)
          },
          error => {
            return Promise.reject(error);
          }
        );
      },
      getGiveAways() {
        return ApiService.getGiveAways().then(
          response => {
            return Promise.resolve(response)
          },
          error => {
            return Promise.reject(error);
          }
        );
      },
      getClientReviews() {
        return ApiService.getClientReviews().then(
          response => {
            return Promise.resolve(response)
          },
          error => {
            return Promise.reject(error);
          }
        );
      },
      getLatestGiveAway() {
        return ApiService.getLatestGiveAway().then(
          response => {
            return Promise.resolve(response)
          },
          error => {
            return Promise.reject(error);
          }
        );
      },
    }
}