import api from './api'

class ApiService {

    getPackages(){
        return api.get("/Packages");
    }
    getGiveAways(){
        return api.get("/GiveAways");
    }
    getClientReviews(){
        return api.get("/ClientReviews");
    }
    getLatestGiveAway(){
        return api.get("/LatestGiveAway");
    }

}
export default new ApiService();