import Vue from 'vue';
import Vuex from 'vuex';
import {ApiValues} from './ApiModule';

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    ApiValues
  },
  state: {
    packages: []
  },
  mutations: {
    addPackage (state, p) {
      state.packages.push(p);
    },
    removePackage(state, index){
        state.packages.splice(index, 1);
    }
  }
})